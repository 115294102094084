import React, { useContext, useState, useEffect } from "react";



import useProductResultPage from "../../../hooks/useProductResultPage";
import useVehicleSidebar from "../../../hooks/useVehicleSidebar";
import FacetOptionSwitch from "../facet/facetOptionSwitch/facetOptionSwitch";
import SideBarBlockWrapper from '../shared/sideBarBlockWrapper';

const VehicleSelect = ({
  dividerTop = false,
  dividerBottom = false,
  styling, 
  displayNameOverride,
  textSize,
  bold
}) => {

  const selectedFacets = useProductResultPage(x => x.selectedFacetOptions);
  const { isVehicleSelected } = useVehicleSidebar(selectedFacets);

  return (isVehicleSelected ? <FacetOptionSwitch
    brand={'primary'}
    facetName={"Fitment Filter"}
    displayNameOverride={displayNameOverride ? displayNameOverride : "Hide Universal Fit"}
    facetOptionValueId={1}
    dividerTop={dividerTop} 
    dividerBottom={dividerBottom}
    styling={styling}
    textSize={textSize}
    typographyProps={bold ? {font: "bold"} : {}}
  /> : false
  );
};

export default VehicleSelect;


