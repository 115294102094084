export const AppName = 'Prp'
export const FeatureFlags = [
    "PRP_SPA",
    "PRP_Default_Grid_Layout",
    "PRP_Mobile_Facet_Selection",
    "Add_To_List_Badge_Expanded",
    "PRP_Vehicle_Top_Section_Enabled",
    "Product_Card_Share",
    "PRP_SearchProviderSwitch",
    "Ribbon_Text_Dollar_Vs_Percentage",
    "Engine_Fitment_Master_Flag",
    "FrontEnd_Engine_Fitment_UX",
    "FrontEnd_CustomerProject_Drawer_Enabled",
    "PRP_New_Engine_Fitment_Checks",
    "PRP_FrontEnd_Promo_Preview"
]
export const DisableOldVehicleContext = true;