const parseVehicleFromSelectedFacet = (selectedFacets, fitmentDetails,engineFitmentPageTitle, isProductCard) => {
  if (!selectedFacets && (!fitmentDetails?.displayValue || !fitmentDetails?.engineFitmentDisplayValue))
    return null;
  
  if (fitmentDetails && (fitmentDetails.displayValue || fitmentDetails.engineFitmentDisplayValue)) {
    if (fitmentDetails.engineFitmentDisplayValue?.length > 0){
      console.log("New Engine Interaction")
    }
    let projectName = ""
    if (isProductCard)
      projectName = fitmentDetails.displayValue ?? fitmentDetails.engineFitmentDisplayValue
    else {
      if((fitmentDetails?.engineFitmentDisplayValue || engineFitmentPageTitle) && fitmentDetails.displayValue) {
        const engineDisplayName = engineFitmentPageTitle ? engineFitmentPageTitle : fitmentDetails.engineFitmentDisplayValue 
        projectName = fitmentDetails.displayValue + ` - ${engineDisplayName}`
      }
      else 
        projectName = fitmentDetails.displayValue ?? fitmentDetails.engineFitmentDisplayValue
    }

    return projectName
  }

  const matchingFacetOption = selectedFacets.filter(
    (selectedFacet) =>
      selectedFacet.displayText === "Race Type" ||
      selectedFacet.displayText === "Fitment"
  );
  if (matchingFacetOption.length === 0) return null;
  return matchingFacetOption[0].searchResponseFacetOptions[0].displayValue;
};

const useVehicleSidebar = (
  selectedFacets, 
  fitmentDetails,
  engineFitmentPageTitle,
  isProductCard = false
) => {
  const vehicleFacet = parseVehicleFromSelectedFacet(selectedFacets, fitmentDetails, engineFitmentPageTitle, isProductCard);

  return {
    label: vehicleFacet ? "Shopping for your:" : "Find the right parts for your vehicle",
    isVehicleSelected: vehicleFacet ? true : false,
    vehicleName: vehicleFacet
  };
};

export default useVehicleSidebar;
