import useProductResultPage from "../../../../hooks/useProductResultPage";

import Typography from 'Clutch/Atoms/Typography'
import Form from 'Clutch/Atoms/Form'
import styles from '../../sidebar.module.scss'
import { useState } from 'react'
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import ClassNames from 'classnames'

import { usePrpStore } from '../../../../contexts/prpContext'
import SideBarBlockWrapper from "../../shared/sideBarBlockWrapper";

const FacetOptionSwitch = (
    {
        facetName,
        facetOptionValueId,
        brand,
        intent,
        children,
        displayNameOverride = null,
        dividerTop = false,
        dividerBottom = false,
        typographyProps,
        onClick,
        styling,
        textSize
    }) => {

    const thisFacet = useProductResultPage(x => x.searchResponseFacets.find(x => x.facetName === facetName))

    const [loading, setLoading] = useState(false);

    const Navigate = usePrpStore(x => x.Navigate)

    const onClickFacet = (e) => {
        if (onClick) {
            onClick()
        }
        Navigate(e, thisFacetOption.facetOptionLink.url, setLoading)
    }

    if (!thisFacet) {
        return false;
    }

    const thisFacetOption = thisFacet.searchResponseFacetOptions.find(x => x.id === facetOptionValueId)

    if (!thisFacetOption) {
        return false;
    }

    return (
        <>
            <SideBarBlockWrapper styling={styling} dividerBottom={dividerBottom} dividerTop={dividerTop}>
                <CoveringSpinner isLoading={loading} opacityWhenLoading={0.5} >
                    <div className={styles.container} onClick={(e) => onClickFacet(e)} data-testid={`facet-switch-${displayNameOverride ?? thisFacetOption.displayValue}-${facetOptionValueId}`}>
                        <Form.Switch checked={thisFacetOption.isSelected} brand={brand} intent={intent} onChange={() => {}}/>
                        <Typography size={textSize ?? 1} className={styles.switchLabel} {...typographyProps}>
                            {displayNameOverride ?? thisFacetOption.displayValue}
                        </Typography>
                    </div>
                    {children}
                </CoveringSpinner>
            </SideBarBlockWrapper>

        </>)
}

export default FacetOptionSwitch