import React, { useContext } from "react";

import Card from "Clutch/Atoms/Card";
import Button from "Clutch/Molecules/Button";
import Typography from "Clutch/Atoms/Typography";
import Svg from "Clutch/Atoms/Svg";

import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { useFeatureFlag } from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";

import styles from "./selectVehiclePrompt.module.scss";

const SelectVehiclePrompt = () => {
  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag");
  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );

  const openAddOrSelectCustomerProjectExperience = useVehicleStore(
    (state) => state.openAddOrSelectCustomerProjectExperience
  );
  const { dispatch: navigationDispatch } = useContext(NavigationContext);

  return (
    <Card
      fill
      noPadding
      layer={0}
      className={styles.card}
    >
      <div className={styles.cardContentsNoVehicle}>
        <div className={styles.title}>
          <div className={styles.spaceBetweenUnselected}>
            <div className={styles.itemText}>
              <Svg icon={"car"} size={1.25} className={styles.svg} />
              <Typography className={styles.exactFitText}>
                Find Parts That Fit Your Vehicle
              </Typography>
            </div>
            <Button
              brand={"secondary"}
              size={"small"}
              className={styles.changeSelected}
              onClick={() =>
                customerProjectDrawerEnabled
                  ? navigationDispatch({
                      type: "setCustomerProjectDrawerState",
                      payload: {
                        garageState: {
                          isCustomerProjectDrawerOpen: true,
                        },
                      },
                    })
                  : openAddOrSelectCustomerProjectExperience(true)
              }
              segmentEvent={createClickedSegmentEvent("PrpSelectVehicle")}
              testingName={"PrpSelectVehicle"}
            >
              <Typography font={"bold"}>
                {customerProjectDrawerEnabled
                  ? "Select A Vehicle/Engine"
                  : "Select A Vehicle"}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SelectVehiclePrompt;