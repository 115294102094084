import React from 'react';

import IncompleteYmmSelector from './incompleteYmmSelector';
import SelectVehiclePrompt from './selectVehiclePrompt';
import VehicleSelectedDisplay from './vehicleSelectedDisplay';

import useVehicleSidebar from "../../hooks/useVehicleSidebar";
import useProductResultPage from "../../hooks/useProductResultPage";
import { useFeatureFlag } from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import getFitmentDetailsFromSelectedFacets from "Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets"

const VehicleOptions = () => {
    try {
        const selectedFacetOptions = useProductResultPage(x => x.selectedFacetOptions)
        const incompleteYmmPage = useProductResultPage(x => x.incompleteYmmPage)
        const engineFitmentPageTitle = useProductResultPage(x => x.engineFitmentPageTitle)

        const selectedFacets = selectedFacetOptions || [];
        const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag")
        const engineFitmentChecksEnabled = useFeatureFlag("PRP_New_Engine_Fitment_Checks")
        //can speed things up when engineFitmentChecksEnabled is permanent
        const fitmentDetailsFromSelectedFacets = getFitmentDetailsFromSelectedFacets(selectedFacets)
        const fitmentDetailsFromFitmentFacet = selectedFacets.find(x => x.fitmentDetails != null)?.fitmentDetails
        const fitmentDetails = engineFitmentChecksEnabled ? fitmentDetailsFromSelectedFacets : fitmentDetailsFromFitmentFacet || {}
        const { isVehicleSelected } = useVehicleSidebar(selectedFacets, fitmentDetails, engineFitmentPageTitle);

        if (!isVehicleSelected)
            return <SelectVehiclePrompt />

        if (incompleteYmmPage) {
            return <IncompleteYmmSelector />
        }

        return <VehicleSelectedDisplay /> 
        
    } catch (err) {
        console.error('There was an issue rendering the vehicle options section', err)
    }
}


export default VehicleOptions