import {useEffect, useState} from "react";
import useProductResultPage from "../../hooks/useProductResultPage";
import Facet from "./facet/facet";
import VehicleSelect from "./vehicleSelect/vehicleSelect";
import {useInView} from 'react-intersection-observer'
import Divider from "Clutch/Atoms/Divider";
import styles from "./sidebar.module.scss";
import FacetOptionSwitch from "./facet/facetOptionSwitch/facetOptionSwitch";
import FacetOption from "./facet/facetOption/facetOption";
import ZipCodeInput from "./zipCodeInput/zipCodeInput";
import SearchWithin from "./searchWithin/searchWithin";
import {usePrpStore} from '/contexts/prpContext'
import ShareButton from "./shareButton/shareButton";
import {useUserStore} from "Clutch/Stores/UserStore/UserStore";

const Sidebar = ({}) => {
    const skeleton = useProductResultPage(x => x.skeleton)
    const searchResponseFacets = useProductResultPage(x => x.searchResponseFacets)
    const isFollow = useProductResultPage(x => x.isFollow)
    const isIndex = useProductResultPage(x => x.isIndex)
    const lazyLoadedFacetsHydrated = useProductResultPage(x => x.lazyLoadedFacetsHydrated)
    const isMobile = usePrpStore(x => x.isMobile)

    const [isWaiting, setIsWaiting] = useState(false);
    const LoadLazyFacets = usePrpStore(x => x.LoadLazyFacets)
    let hasHitFirstLazyLoadedFacetGroup = false;
    const isBot = useUserStore((x) => x.context.isBot);
    const searchProducts = useProductResultPage(x => x.searchProducts)
    const debugModeEnabled = searchProducts?.some(
        (x) => x.boostingRules?.length || x.scores?.length
    );

    const {ref, inView} = useInView({
        triggerOnce: true,
        threshold: 0,
        rootMargin: '200px 0px',
    })

    const LazyLoadFacetsIntersectionObserver = () => {

        if (hasHitFirstLazyLoadedFacetGroup || skeleton || isBot()) {
            return false;
        }

        hasHitFirstLazyLoadedFacetGroup = true;
        return (<span ref={ref}></span>)
    }
    useEffect(() => {
        if (isBot() && (isFollow || isIndex)) {
            LoadLazyFacets()
        }
    }, [isFollow, isIndex])


    useEffect(() => {
        if (lazyLoadedFacetsHydrated) {
            window.dispatchEvent(new CustomEvent("clarityautomate.initialized"))
        }
    }, [lazyLoadedFacetsHydrated])


    useEffect(() => {
        inView && LoadLazyFacets()
    }, [inView])

    // rendering these on mobile is really expensive (especially on a slow phone)
    if (isMobile) {
        return false
    }

    return (
        <>
            <h2 className={styles.h2}>Navigation Links</h2>
            <div
                data-testid={"navigation_desktop"}
                style={{paddingRight: "0.75rem"}}
            >
                <SearchWithin dividerBottom/>
                <ZipCodeInput dividerBottom/>
                <ShareButton dividerBottom/>

                <FacetOptionSwitch
                    intent={'positive'}
                    dividerBottom
                    facetName={"Availability"}
                    facetOptionValueId={-1}
                    displayNameOverride={"In Stock & Ships Fast"}
                    typographyProps={{intent: "positive", font: "bold"}}
                />

                {(searchResponseFacets || []).map((facet, i) => {
                    return (

                        <div
                            className={styles.facetGroup}
                            key={`sidebar_facet_wrapper_${facet.facetName}_${i}`}
                        >
                            {facet.lazyLoaded && LazyLoadFacetsIntersectionObserver()}
                            {facet.facetName === "Ships for Free" ||
                            facet.facetName === "Rebates" ? (
                                facet.searchResponseFacetOptions.find((x) => x.id == 1) ==
                                null ? null : (
                                    <>
                                        <Divider className={styles.divider}/>
                                        <FacetOption
                                            facetName={facet.facetName}
                                            facetOption={facet.searchResponseFacetOptions.find(
                                                (x) => x.id === 1
                                            )}
                                            isSelected={facet.isSelected}
                                            isMultiSelect={false}
                                            setIsLoading={setIsWaiting}
                                            hidden={false}
                                        />
                                    </>
                                )
                            ) : (
                                (facet.facetName === "Garage Sale" || facet.facetName === "Fitment Filter") && !searchResponseFacets?.find(x => x.facetName === "Discounts")?.searchResponseFacetOptions?.find(x => x.id === 3)?.isSelected ? null :
                                    <>
                                        {i == 0 ? null : <Divider className={styles.divider}/>}
                                        <Facet
                                            key={`sidebar_facet_${facet.facetName}_${i}`}
                                            index={i}
                                            facet={facet}
                                            debugModeEnabled={debugModeEnabled}
                                        />
                                    </>
                            )}
                        </div>
                    );
                })}
                <FacetOptionSwitch
                    facetName={"Discounts"}
                    facetOptionValueId={3}
                    dividerTop={true}
                    brand={'secondarypalette_purple'}
                />
            </div>
        </>
    );
};

export default Sidebar;
