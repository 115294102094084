import React, { useContext, useState } from "react";
import classNames from "classnames";

import Card from "Clutch/Atoms/Card";
import Panel from "Clutch/Atoms/Panel";
import Button from "Clutch/Molecules/Button";
import Typography from "Clutch/Atoms/Typography";
import Svg from "Clutch/Atoms/Svg";
import VehicleSelect from "../sidebar/vehicleSelect/vehicleSelect";
import TextLink from "Clutch/Atoms/TextLink";

import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import useVehicleSidebar from "../../hooks/useVehicleSidebar";
import useProductResultPage from "../../hooks/useProductResultPage";
import { NotificationContext } from "Clutch/Contexts/NotificationContext/NotificationContext";
import {
  showAddToGarage,
  showUpdateCustomerProject,
  addToGarage,
  updateCustomerProject,
} from "/Utilities/garageUtilities";
import { useFeatureFlag } from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import getFitmentDetailsFromSelectedFacets from "Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets";
import getMatchingProject from "Clutch/Utilities/CustomerProjectUtilities/getMatchingProject";

import styles from "./vehicleSelectedDisplay.module.scss";

const VehicleSelectedDisplay = () => {
  const [loading, setLoading] = useState(false);

  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions
  );
  const totalSearchResults = useProductResultPage((x) => x.totalSearchResults);
  const engineFitmentPageTitle = useProductResultPage(
    (x) => x.engineFitmentPageTitle
  );

  const selectedFacets = selectedFacetOptions || [];
  const customerProjects = useVehicleStore(
    (state) => state.context.customerProjects
  );
  const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag");
  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );
  const engineFitmentChecksEnabled = useFeatureFlag(
    "PRP_New_Engine_Fitment_Checks"
  );
  //can speed things up when engineFitmentChecksEnabled is permanent
  const fitmentDetailsFromSelectedFacets =
    getFitmentDetailsFromSelectedFacets(selectedFacets);
  const fitmentDetailsFromFitmentFacet = selectedFacets.find(
    (x) => x.fitmentDetails != null
  )?.fitmentDetails;
  const fitmentDetails = engineFitmentChecksEnabled
    ? fitmentDetailsFromSelectedFacets
    : fitmentDetailsFromFitmentFacet || {};
  const { vehicleName } = useVehicleSidebar(
    selectedFacets,
    fitmentDetails,
    engineFitmentPageTitle
  );
  const updateCustomerProjectData = useVehicleStore(
    (x) => x.updateCustomerProjectData
  );

  const { PushNotification } = useContext(NotificationContext);
  const openAddOrSelectCustomerProjectExperience = useVehicleStore(
    (state) => state.openAddOrSelectCustomerProjectExperience
  );
  const selectCustomerProject = useVehicleStore(
    (state) => state.selectCustomerProject
  );
  const { dispatch: navigationDispatch } = useContext(NavigationContext);
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject);

  const canAdd = showAddToGarage(customerProjects, fitmentDetails);
  const canUpdate = showUpdateCustomerProject(customerProjects, fitmentDetails);

  const hasYMM =
    fitmentDetails.year && fitmentDetails.make && fitmentDetails.model;
  const hasRaceType = fitmentDetails.raceType || fitmentDetails.raceTypeName;
  const hasSubtext =
    (hasYMM || hasRaceType) &&
    customerProjectDrawerEnabled &&
    (engineFitmentPageTitle ||
      fitmentDetails.engineFitmentDisplayValue ||
      (!canUpdate && !canAdd));

  return (
    <>
      {totalSearchResults && (
        <Typography font={"bold"} className={styles.resultsTitle}>
          {totalSearchResults} products fit your{" "}
        </Typography>
      )}
      <Card
        fill
        noPadding
        layer={1}
        className={styles.card}
      >
        <div className={styles.cardContents}>
          <div className={styles.title}>
            <>
              <div className={styles.spaceBetween}>
                <div className={styles.itemText}>
                  <Panel layer={0} className={styles.svg}>
                    {totalSearchResults.toString() === "0" ? (
                      <Svg icon={"unavailable"} size={2} intent={"negative"} />
                    ) : (
                      <Svg icon={"check"} size={2} intent={"positive"} />
                    )}
                  </Panel>
                  <div className={styles.completeVehicleText}>
                    {hasYMM ? (
                      <Typography
                        data-testid={"PrpFitmentDetailsTitle"}
                        font={"bold"}
                      >
                        {fitmentDetails.year} {fitmentDetails.make}{" "}
                        {fitmentDetails.model}
                      </Typography>
                    ) : (
                      hasRaceType && (
                        <Typography
                          data-testid={"PrpFitmentDetailsTitle"}
                          font={"bold"}
                        >
                          {fitmentDetails.raceType ||
                            fitmentDetails.raceTypeName}
                        </Typography>
                      )
                    )}
                    {customerProjectDrawerEnabled &&
                    (engineFitmentPageTitle ||
                      fitmentDetails.engineFitmentDisplayValue) ? (
                      <Typography
                        data-testid={
                          hasYMM || hasRaceType
                            ? "PrpFitmentDetailsSubtitle"
                            : "PrpFitmentDetailsTitle"
                        }
                        className={styles.vehicleSubtext}
                        font={hasYMM || hasRaceType ? undefined : "bold"}
                      >
                        {engineFitmentPageTitle ??
                          fitmentDetails.engineFitmentDisplayValue}
                      </Typography>
                    ) : (
                      customerProjectDrawerEnabled &&
                      !canUpdate &&
                      !canAdd && (
                        <TextLink
                          onClick={async () => {
                            const selectedProject = getMatchingProject(
                              fitmentDetails,
                              customerProjects
                            );
                            await selectCustomerProject(
                              selectedProject.projectId,
                              null
                            );

                            navigationDispatch({
                              type: "setCustomerProjectDrawerState",
                              payload: {
                                garageState: {
                                  isCustomerProjectDrawerOpen: true,
                                  customerProjectDrawerView:
                                    "UPDATE_PROJECT_ADD_ENGINE",
                                },
                              },
                            });
                          }}
                          className={styles.vehicleSubtext}
                          data-testid={"PrpAddEngine"}
                          intent={"action"}
                        >
                          {"Add Engine >"}
                        </TextLink>
                      )
                    )}
                  </div>
                  <div
                    className={
                      hasSubtext
                        ? classNames(
                            styles.editIcon,
                            styles.hideDesktop,
                            styles.withSubtext
                          )
                        : classNames(
                            styles.editIcon,
                            styles.hideDesktop,
                            styles.onlyTitle
                          )
                    }
                    onClick={() =>
                      customerProjectDrawerEnabled
                        ? navigationDispatch({
                            type: "setCustomerProjectDrawerState",
                            payload: {
                              garageState: {
                                isCustomerProjectDrawerOpen: true,
                              },
                            },
                          })
                        : openAddOrSelectCustomerProjectExperience(true)
                    }
                  >
                    <Svg icon={"edit"} size={1} />
                  </div>
                </div>
                <div className={styles.controls}>
                  <VehicleSelect
                    styling={styles.switch}
                    textSize={0.75}
                    bold
                    displayNameOverride={`Show Direct Fit Parts`}
                  />
                  <div className={styles.buttonWrapper}>
                    <Button
                      intent={"subdued"}
                      size={"small"}
                      className={styles.changeSelected}
                      onClick={() =>
                        customerProjectDrawerEnabled
                          ? navigationDispatch({
                              type: "setCustomerProjectDrawerState",
                              payload: {
                                garageState: {
                                  isCustomerProjectDrawerOpen: true,
                                },
                              },
                            })
                          : openAddOrSelectCustomerProjectExperience(true)
                      }
                      segmentEvent={createClickedSegmentEvent(
                        "PrpChangeVehicle"
                      )}
                      testingName={"ChangeVehicle"}
                    >
                      <Typography className={styles.nowrap} font={"bold"}>
                        Change Selected
                      </Typography>
                    </Button>
                    {canUpdate && (
                      <Button
                        isWaiting={loading}
                        brand={"secondary"}
                        size={"small"}
                        className={styles.vehicleButton}
                        onClick={() =>
                          updateCustomerProject(
                            fitmentDetails,
                            canUpdate,
                            setLoading,
                            selectCustomerProject,
                            updateCustomerProjectData,
                            PushNotification,
                            vehicleName
                          )
                        }
                        testingName={"PrpUpdateGarage"}
                        segmentEvent={createClickedSegmentEvent(
                          "UpdateProject"
                        )}
                      >
                        <Typography className={styles.nowrap} font={"bold"}>
                          Update Vehicle
                        </Typography>
                      </Button>
                    )}
                    {!canUpdate && canAdd && (
                      <Button
                        isWaiting={loading}
                        brand={"secondary"}
                        size={"small"}
                        className={styles.vehicleButton}
                        onClick={() =>
                          addToGarage(
                            fitmentDetails,
                            setLoading,
                            selectCustomerProject,
                            addCustomerProject,
                            PushNotification,
                            vehicleName
                          )
                        }
                        testingName={"PrpAddToGarage"}
                        segmentEvent={createClickedSegmentEvent("AddProject")}
                      >
                        <Typography className={styles.nowrap} font={"bold"}>
                          Add To Garage
                        </Typography>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Card>
    </>
  );
};

export default VehicleSelectedDisplay;
