import React, { useEffect, useState } from "react";
import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import Popover from "Clutch/Components/Popover";
import Card from "Clutch/Atoms/Card";
import styles from "./userExperience.module.scss";
import Form from "Clutch/Atoms/Form";
import SetCookie from "Clutch/Utilities/CookieUtilties/SetCookie";
import CoveringSpinner from "Clutch/Atoms/CoveringSpinner";
import CookieReader from "Utilities/CookieUtilties/CookieReader";

import { usePrpStore } from "../../contexts/prpContext";

const UserExperience = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNewUserExperienceEnabled, setIsNewUserExperienceEnabled] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const Navigate = usePrpStore((x) => x.Navigate);

  useEffect(() => {
    const forceNewUserCookie = CookieReader("forceNewUser");
    if (forceNewUserCookie && forceNewUserCookie === "true")
      setIsNewUserExperienceEnabled(true);
    else setIsNewUserExperienceEnabled(false);
  }, []);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleOnChange = (e) => {
    setIsLoading(true);
    SetCookie("forceNewUser", !isNewUserExperienceEnabled, 365);
    setIsNewUserExperienceEnabled(!isNewUserExperienceEnabled);
    Navigate(e, window.location.href, setIsLoading);
  };

  const areaContent = (
    <Area
      className={styles.area}
      title={`User Experience: ${
        isNewUserExperienceEnabled ? "New" : "Current"
      }`}
      intent={"positive"}
    >
      <Typography size={0.75}>{`User Experience: ${
        isNewUserExperienceEnabled ? "New" : "Current"
      }`}</Typography>
    </Area>
  );

  const popoverContent = (
    <div className={styles.popoverContent}>
      <CoveringSpinner isLoading={isLoading} opacityWhenLoading={0.5}>
        <div className={styles.container} onClick={handleOnChange}>
          <Form.Switch checked={isNewUserExperienceEnabled} brand={"primary"} />
          <Typography size={1}>Simulate New User Experience</Typography>
        </div>
      </CoveringSpinner>
    </div>
  );

  return (
    <Card noMargin noPadding className={styles.card}>
      <div
        className={styles.recallsWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Popover content={popoverContent} isOpen={isOpen}>
          {areaContent}
        </Popover>
      </div>
    </Card>
  );
};

export default UserExperience;
