const productDetailsFragment = `
  productPageId
  skuBaseNumber
  displayTitle
  subtitle
  rating
  reviewCount
  availability
  productPageUrl
  absoluteProductPageUrl
  segmentCategorization
  imageLink
  brand
  brandImageUrl
  unit
  isGarageSale
  isNewProduct
  highPrice
  lowPrice
  superMarketAffinity
  hasActiveRebate
  ribbonText
  pricing {
    hasMultiplePrices
    salePriceString
    percentOffString
    strikeThroughPrice   
  }
  specs{
    title
    value
    requiresVariantSelection
    isKeySpec
    ordinality
  }
  skuVariantSummaries{
    skuNumber
    skuName
    skuBase
    availability
  }
`

const productResultPageProductsFragment = `
searchProducts {
  skuBaseNumber
  skuBaseName
  position
  lazyLoad
  matchedFields
  scores {
    value
    field
    order
  }
  boostingRules {
    boostName
    appliedBoostingConfiguration
    boostPositions
  }
  lazyLoadData {
    title
    url
  }skuBaseDetails{
  ${productDetailsFragment}
  }
}
`

const articleSearchFragment = `
articleSearchResponse{
  articleDetails{
  url
  title
  description
  articleThumbnailUrl
  id
  authors
}
}
`

const linkRequestFragment = `
linkRequest{
  brands
  requestIdentifier
  categories
  subcategories
  partTypes
  year
  make
  model
  vehicleBase
  markets
  superMarkets
  raceType
  engineFamilies
  engineSubfamilies
  transmissionFamilies
    rearEndFamilies
    applications
    attributes
    productLines
    misc
    rebates
    canShipFree
    shipsFree
    platforms
    searchQuery
    availabilities
    garageSales
    chassis
    sanctioningBodies
    engineManufacturer
    engineMake
    engineSeries
    engineConfiguration
    engineVersion
    engineQualifier
    engineDisplacement
    engineFuelSystem
    engineCamshaftType
    engineCode
    price {
      min
      max
    }
    rating {
      min
      max
    }
    sortType
}`

const searchResponseFacetsFragment = `
  searchResponseFacets {
    facetName
    hasShowMore
    isForFacetResultPage
    isForTopCategories
    shouldUseImageUrlForTopCategories
    showTopCategoryTitle
    isForSearchFacetResultPage
    isMultiSelect
    isSelected
    lazyLoaded
    isCriticalAttribute
    facetType
    displayPosition
    searchResponseFacetOptions {
      orderedSortIndex
      relevanceSortIndex
      thumbnailImageUrl
      displayValue
      resultCount
      isSelected
      excludeFromTopCategories
      parentFacetIdentifiers
      id
      displayPosition
      facetOptionLink {
        url
        noFollow
      }
      ${linkRequestFragment}
    }
  }  
`


const productResultPageGuts = `
    isFollow
    engineFitmentPageTitle
    isIndex
    should404
    redirectUrl
    misoId
    misoEngine
    isFacetResultPage
    isNoResultPage
    isSearchPage
    isSearchFacetResultPage
    qualifiedForSuggestedFacet
    suggestedFacetsApplied
    incompleteYmmPage
    currentPageLinkRequestJson
    currentPage
    canonicalUrl
    prevUrl
    nextUrl
    pageTitle
    displayTitle
    totalSearchResults
    initialSideBarFacetCount
    searchWithinUrl
    representativeImageUrl
    attribution{
      phrased_query
      is_phrased_query
      ymm_inference
      part_type_inference
      is_ymm
      is_racetype
      is_transmission
      is_rear_axle
      is_engine
      is_platform
      is_brand
      is_new_engine
      search_query_word_count
      multiple_fitment_facets
      short_description_match_candidate
      house_brand_boost_candidate
      rules
    }
    sortOrders {
      title
      url
      isSelected
    }
    bottomPageLinks{
      title
      isLateralFacet
      links {
        text
        url
        noFollow
      }
    }
    searchSpelling  {
      explaination
      correctedQuery
    }
    selectedFacetOptions {
      displayText
      facetType
      engineFacetType
      fitmentDetails{
        raceType
        make
        model
        year
        raceTypeId
        makeId
        modelId
        vehicleBaseId
      }
      engineFitmentDetails {
        engineManufacturerId
        engineMakeId
        engineSeriesId
        engineConfigurationId
        engineVersionId
        engineQualifierId
        engineDisplacementId
        engineCodeId
        engineCamshaftTypeId
        engineFuelSystemId
      }
      searchResponseFacetOptions {
        displayValue
        id
        facetOptionIdentifier
        parentFacetIdentifiers
        facetOptionLink {
          url
          noFollow
        }
      }
    }
    instrumentation {
      bloomreachInitialPageLoad {
          search_term
          title
          cat_id
          cat
          ptype       
      }
      metrics {
        searchProviderName
        searchProviderRequestJson
        searchProviderRequest {
            diversification {
                key
                value {
                    minimum_distance
                }
            }
        }
        boostingTags
        boostingCampaigns {
          matchedTags
          matchedQueries
          name
          campaignJson
        }
        productCampaigns {
          matchedTags
          matchedQueries
          name
          boostedSkuDetails {
            skuBaseNumber
            productPageUrl
            imageLink
            displayTitle
          }
          appliedBoosting
        }
      }
      featureFlags{
        featureFlagName
        treatment
      }
    }
    metaDescription
    pageContent{
      html
      extendedHtml
      brandImageUrl
      bannerImageUrl
      isMakerPage
      makerContentMobileHeight
      makerContentTabletHeight
      makerContentDesktopHeight
    }
    ${articleSearchFragment}
    ${productResultPageProductsFragment}
    ${searchResponseFacetsFragment}
    breadcrumb {
      displayText
      ordinality
      url
      searchResponseFacetOption {

        facetOptionLink {
          url
          noFollow
        }
      }
    }

    pageContent {
      html
      extendedHtml
      brandImageUrl
      bannerImageUrl
      css
      metaDescription
      isMakerPage
      makerContentMobileHeight
      makerContentTabletHeight
      makerContentDesktopHeight
    }
    campaign {
      campaignType
      htmlText
      assets{
        placement
        device
        rows{
          title
          scrollable
          sections{
            image
            desktopImage
            mobileImage
            imageAlt
            href
            targetBlank
            overlayText
            overlaySubText
            seoFollow
          }
        }
        cards{
          image
          imageAlt
          href
        }
      }
      campaignPositions{
        campaignPositions
        campaignGridPositions
        campaignCardPositions
      }
    }
    appliedPhrases {
        phrases {
            key
            value
        }
        sminetAddNewPhraseUrl
        sminetUrl
    }
    facetRuleTargets {
        id
        name
        inherit
        query
        isIndex
        isFollow
        diversification
    }
    facetRules {
        targetId
        id
        ruleApplicationOrdinality
        displayPosition
        facetType
        facetTypeDisplayName
        engineFacetType
        isLocalAttribute
        isLocalAttributeValue
        type
        ids
        facetIds {
            key
            value
        }
        attributeName
        sminetUrl
        isFollow
        isFollowEntirePage
        isIndex
    }
    sminetAddNewFacetRuleUrl
    productRecalls {
      searchRecallId
      sopSkuBase
      url
      query
      boostingPosition
      isEnabled
      sminetUrl
    }
    sminetAddProductRecallUrl
    misoOrderByConfig {
      withoutFitment {
        indexForUserSortOrdering
        misoOrderByRules {
          field
          order
        }
      }
      withYmm {
        indexForUserSortOrdering
        misoOrderByRules {
          field
          order
        }
      }
      withRaceType {
        indexForUserSortOrdering
        misoOrderByRules {
          field
          order
        }
      }
      withDriveline {
        indexForUserSortOrdering
        misoOrderByRules {
          field
          order
        }
      }
      withTransmission {
        indexForUserSortOrdering
        misoOrderByRules {
          field
          order
        }
      }
      withPlatform {
        indexForUserSortOrdering
        misoOrderByRules {
          field
          order
        }
      }
      withBrand {
        indexForUserSortOrdering
        misoOrderByRules {
          field
          order
        }
      }
      treatment
    }
    filteringRule {
      conditions {
        key
        value
        isNegated
      }
      operators
    }
`


const urlOnlyPagingQuery = `
query getPage($url: String, $searchProvider: String, $skippedCriticalAttributes: [Int!] = null, $fetchPrecedingPages : Boolean){
  productResultPageFromUrlOnly(url : $url, searchProvider: $searchProvider, skippedCriticalAttributes: $skippedCriticalAttributes, fetchPrecedingPages: $fetchPrecedingPages) {
    nextUrl
    currentPage
    ${productResultPageProductsFragment} 
  }
}
`


const allPageFacetsUrlOnlyQuery = `
query getLinks($url:String, $skippedCriticalAttributes: [Int!] = null){
  allPageFacetsByURLOnly(url : $url, skippedCriticalAttributes: $skippedCriticalAttributes) {
    ${searchResponseFacetsFragment}
    success
  }
}`

const urlOnlyQuery = `
query getPage($url: String, $skippedCriticalAttributes: [Int!] = null, $lazyLoadFacets: Boolean = null, $fetchPrecedingPages : Boolean = null){
  productResultPageFromUrlOnly(url : $url, skippedCriticalAttributes: $skippedCriticalAttributes, lazyLoadFacets: $lazyLoadFacets, fetchPrecedingPages: $fetchPrecedingPages) {
    ${productResultPageGuts}
  }
}
`

const getPreFetchQuery = `
query getPreFetchPage($url: String){
  retrievePrefetchedPRP(url : $url) {
    ${productResultPageGuts}
  }
}
`

export {allPageFacetsUrlOnlyQuery, urlOnlyQuery, urlOnlyPagingQuery, productDetailsFragment, getPreFetchQuery}
