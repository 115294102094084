import { useContext } from 'react'
import useProductResultPage from "../../../hooks/useProductResultPage";
import useVehicleSidebar from "../../../hooks/useVehicleSidebar";
import {usePrpStore} from '/contexts/prpContext'
import ClassNames from 'classnames'
import SelectedFacets from '../../options/selectedFacets/selectedFacets';
import TextLink from "Clutch/Atoms/TextLink";
import LayerColorFinder from "Clutch/Utilities/LayerColorFinder";
import Typography from "Clutch/Atoms/Typography";
import Svg from "Clutch/Atoms/Svg";
import styles from './sidebarDrawerFacets.module.scss'
import styled from "styled-components";
import {useVehicleStore} from "Clutch/Stores/VehicleStore";
import { useFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag'
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";

const SidebarDrawerFacets = () => {

    const selectedFacets = useProductResultPage(x => x.selectedFacetOptions)

    const {isVehicleSelected, vehicleName} = useVehicleSidebar(selectedFacets);
    const openAddOrSelectCustomerProjectExperience = useVehicleStore(state => state.openAddOrSelectCustomerProjectExperience)
    const CloseSidebarDrawer = usePrpStore(x => x.CloseSidebarDrawer)
    const { dispatch: navigationDispatch } = useContext(NavigationContext);

    const brand = 'secondary'
    const backgroundColor = LayerColorFinder(3, false, brand, null)


    const filterFacetsCount = selectedFacets?.filter(currentFacet => currentFacet.searchResponseFacetOptions).length
    const facetsSelected = filterFacetsCount > 0;

    const impressionDontDelete = useFeatureFlag("Engine_Fitment_Master_Flag")
    const customerProjectDrawerEnabled = useFeatureFlag("FrontEnd_CustomerProject_Drawer_Enabled");

    let buttonText = 'Select Vehicle';
    if (isVehicleSelected)
        buttonText = vehicleName
    else if (customerProjectDrawerEnabled)
        buttonText = 'Select Vehicle/Engine'

    return (
        <div className={facetsSelected ? styles.onlyChips : ClassNames(styles.scroll, styles.facetSelected)}>
            <StyledChip
                backgroundColor={backgroundColor}
                borderColor={backgroundColor}
                onClick={customerProjectDrawerEnabled ? () => {
                    
                    navigationDispatch({
                        type: "setCustomerProjectDrawerState",
                        payload: {
                          garageState: {
                            isCustomerProjectDrawerOpen: true
                          },
                        },
                      })
                      CloseSidebarDrawer()
                } 
                    : () => openAddOrSelectCustomerProjectExperience(true, CloseSidebarDrawer)}
                className={styles.chip}
                as={"div"}
            >
                        <span
                            className={styles.chip_contents}
                            data-testid={`filterByVehicleButton-mobile`}
                        >
                            <Typography
                                size={0.875}
                                className={styles.chip_value}
                            >
                                {buttonText}
                            </Typography>
                            <Svg icon={isVehicleSelected ? "close" : "chevron"} tone={"contrast"} size={0.5}
                                 className={styles.vehicleSelectIcon}></Svg>
                        </span>
            </StyledChip>
            <SelectedFacets isDesktop={false} location={"mobile"} wrap/>
        </div>
    )
}


const StyledChip = styled(TextLink)`
    background-color: ${(props) => `${props.backgroundColor}`};
    border-color: ${(props) => `${props.borderColor}`};
`;

export default SidebarDrawerFacets