import React, {useMemo, useState} from 'react';
import Area from 'Clutch/Molecules/Area';
import Typography from 'Clutch/Atoms/Typography';
import Popover from 'Clutch/Components/Popover';
import Card from 'Clutch/Atoms/Card';
import styles from './isFollowedAndIndexed.module.scss';

const IsFollowedAndIndexed = ({isFollow, isIndex, facetRules, sminetAddNewFacetRuleUrl}) => {
    const [isOpen, setIsOpen] = useState(false);

    const {pageRule, followRules} = useMemo(() => {
        const pageRule = facetRules?.find(rule => rule.isFollowEntirePage !== null || rule.isIndex !== null) || null;
        const followRules = facetRules?.filter(rule => rule.isFollow !== null && rule.isFollow !== undefined) || [];
        return {pageRule, followRules};
    }, [facetRules]);

    const hasPageRule = !!pageRule;
    const hasFollowRules = followRules.length > 0;
    const getCurrentUrl = () => window.location.href;
    const handleMouseEnter = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);
    const handleAddNewRule = (e) => {
        e.preventDefault();
        const currentUrl = new URL(getCurrentUrl());
        let newUrl = sminetAddNewFacetRuleUrl;

        if (currentUrl.pathname.startsWith('/shop')) {
            const encodedPath = encodeURIComponent(currentUrl.pathname);
            newUrl = `${sminetAddNewFacetRuleUrl}?shopUrl=${encodedPath}`;
        }

        window.open(newUrl, '_blank', 'noopener,noreferrer');
    };

    const renderPageStatus = () => (
        <div className={styles.ruleGroup}>
            <Typography size={1} className={styles.ruleGroupTitle}><strong>Page Rule</strong></Typography>
            <ul className={styles.ruleList}>
                <li className={styles.ruleItem}>
                    <div className={styles.ruleInfo}>
                        <Typography size={0.875} className={styles.ruleName}>
                            Follow: {pageRule?.isFollowEntirePage ?? isFollow ? 'True' : 'False'}
                            {pageRule?.isFollowEntirePage === null && ' (Default)'}
                        </Typography>
                        <Typography size={0.875} className={styles.ruleName}>
                            Index: {pageRule?.isIndex ?? isIndex ? 'True' : 'False'}
                            {pageRule?.isIndex === null && ' (Default)'}
                        </Typography>
                    </div>
                    {hasPageRule && (
                        <a
                            href={pageRule.sminetUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.configureLink}
                        >
                            Configure
                        </a>
                    )}
                </li>
            </ul>
        </div>
    );

    const renderFollowRules = () => (
        <div className={styles.ruleGroup}>
            <Typography size={1} className={styles.ruleGroupTitle}><strong>Follow Rules</strong></Typography>
            <ul className={styles.ruleList}>
                {followRules.map((rule, index) => (
                    <Area
                        key={index}
                        intent={rule.isFollow ? 'positive' : 'negative'}
                        className={styles.ruleGroup}
                    >
                        <li className={styles.ruleItem}>
                            <div className={styles.ruleInfo}>
                                <Typography size={0.875} className={styles.ruleName}>
                                    {rule.facetTypeDisplayName || rule.facetType}
                                </Typography>
                                <Typography size={0.75} className={styles.ruleCount}>
                                    Follow: {rule.isFollow ? 'True' : 'False'}
                                </Typography>
                            </div>
                            <a
                                href={rule.sminetUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.configureLink}
                            >
                                Configure
                            </a>
                        </li>
                    </Area>
                ))}
            </ul>
        </div>
    );

    const popoverContent = (
        <div className={styles.popoverContent}>
            {renderPageStatus()}
            {hasFollowRules && renderFollowRules()}
            {!hasPageRule && (
                <a
                    href="#"
                    onClick={handleAddNewRule}
                    className={styles.addLink}
                >
                    Add New Follow or Index Rule
                </a>
            )}
        </div>
    );

    const areaContent = (
        <Area
            className={styles.rule}
            title="Follow and Index Status"
            brand={(hasPageRule || hasFollowRules) ? 'primary' : undefined}
        >
            <Typography size={0.75}>
                {hasPageRule
                    ? 'SEO Rule Present'
                    : hasFollowRules
                        ? 'Follow Rules Present'
                        : 'Default Follow and Index Applied'}
            </Typography>
        </Area>
    );

    return (
        <Card noMargin noPadding className={styles.card}>
            <div
                className={styles.rulesWrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Popover content={popoverContent} isOpen={isOpen}>
                    {areaContent}
                </Popover>
            </div>
        </Card>
    );
};

export default IsFollowedAndIndexed;
